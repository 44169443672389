<template>
    <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5" v-show="currentStep==1">
            <confirm-plan  @prev="()=>this.$router.push('/subscription')" @next="next()"/>
        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-5" v-show="currentStep==2">
            <payment-details ref="paymentDetails" @prev="prev()" @next="savePayments" @onSubscribe="subscribeWithDefaultMethod"/>
        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-5" v-show="currentStep==3">
            <billing-info ref="billingInfo" @prev="prev()" @next="subscribe"/>
        </div>
    </div>
</template>
<script>
import ConfirmPlan from '@/components/functional-components/other/subscription/ConfirmPlan.vue';
import PaymentDetails from '@/components/functional-components/other/subscription/PaymentDetails.vue';
import BillingInfo from '@/components/functional-components/other/subscription/BillingInfo.vue';
import {create_subscription} from '@/apis/others/subscriptions'
export default{
    components: {
        ConfirmPlan,
        PaymentDetails,
        BillingInfo,
    },
    data(){
        return{
            currentStep: 1,
            paymentMethodId:''
        }
    },
    methods:{
        next(){
            this.currentStep+=1;
        },
        prev(){
            this.currentStep-=1;
        },
        subscribe(details){
            this.$refs.billingInfo.loading=true;
            let data={
                paymentMethodId: this.paymentMethodId,
                productId: this.$route.query.plan_id,
                billingAddress: details
            }
            create_subscription(data).then(res=>{
                this.$refs.billingInfo.loading=false;
                this.$refs.paymentDetails.subscribeLoading=false;
                if(res.statusCode==200){
                    this.$bvToast.toast(res.message, {
                        title: 'Subscription Successfull',
                        variant: 'success',
                        solid: true,
                    })
                    setTimeout(() => {
                        this.$router.push({path:'/settings', query:{tab:'subscriptions'}})
                    },[1000]);
                }else{
                    this.$bvToast.toast(res.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    })
                    this.$refs.billingInfo.loading=false;
                    this.$refs.paymentDetails.subscribeLoading=false;
                }

            }).catch(err=>{
                this.$refs.billingInfo.loading=false;
                this.$refs.paymentDetails.subscribeLoading=false;
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    })
            })
        },
        savePayments(id){
            this.paymentMethodId = id;
            this.currentStep+=1;
        },
        subscribeWithDefaultMethod(id){
            this.paymentMethodId = id;
            this.subscribe(null)
        }
    },

}

</script>
<style>
</style>